.nav-span {
  color: #93a603 !important;
}
.navbar {
  z-index: 10;
  top: 0%;
  width: 100%;
}

#root {
  position: relative;
}
