.recipe-image {
  width: 100vw;
  height: 100vh;
  min-height: 70vh;
  object-fit: cover;
}

/* .recipe-header {
  position: relative;
} */

.recipe-ingredients {
  background-color: rgba(213, 239, 242, 0.9);
}

/* .equipment-list {
  position: absolute;
  width: 100%;
  bottom: 1%;
  background-color: rgba(255, 255, 255, 0.9);
} */

.recipe-link {
  color: #d5eff2;
}

.recipe-link:hover {
  color: #729ea2;
}

.favorite-icon:hover {
  transform: scale(1.25);
}
