.dropdown-menu .dropdown-menu {
  margin-left: 170px;
  margin-top: -30px;
}

.main-nav-dropdown .dropdown-toggle::after {
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  vertical-align: 0;
}
