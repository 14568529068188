.truncated {
  display: block;
  white-space: nowrap; /* forces text to single line */
  overflow: hidden;
  text-overflow: ellipsis;
}

.day-month:hover .cart-icon {
  transform: scale(2);
}
