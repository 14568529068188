.rbt-aux {
  display: flex;
  position: absolute;
  top: 30%;
  right: 10px;
}
.rbt-close-content {
  display: none;
}

.rbt > div {
  border: none;
  /* width: 80%;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px; */
}

.rbt > div > input {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.input-group-append > .btn {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
