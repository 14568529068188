$primary: #93a603;
$secondary: #819ea5;
$success: #93a603;
$info: #cea2ac;
$warning: #efd9ce;
$danger: #d97904;
$light: #d5eff2;
$dark: #25283d;

$body-color: #8c7b74;
// $body-bg: #e7ebe8;
$body-bg: #e8f8f9;
$navbar-light-color: #8c7b74;
$navbar-light-hover-color: #645852;
$navbar-light-active-color: #463d39;
$list-group-color: #8c7b74;

$input-placeholder-color: #c7aea3;

/* 
#25283D
#8F3985
#A675A1
#CEA2AC
#EFD9CE
*/

@import '~bootstrap/scss/bootstrap.scss';
