.day-month {
  width: 14.3%;
  min-height: 150px;
}

.nextMonth {
  background-color: #6c757d;
  color: white;
}

.cart-button {
  right: 0;
}
